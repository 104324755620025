import React, { useEffect, useState } from "react";
import "./Payoptions.scss";
import { useDispatch, useSelector } from "react-redux";
import { selectAllPayablesListData } from "../../../../../../_reducers/payables.reducer";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Button, InputGroup } from "react-bootstrap";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { orange } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Avatar from "@mui/material/Avatar";
import { selectPaymentJourneyData } from "../../../../../../_reducers/paymentJourney.reducer";
import { Box, CircularProgress, useStepContext } from "@mui/material";
import { selectAllData } from "../../../../../../_reducers/dashboard.reducer";
import { paymentJourneyActions } from "../../../../../../_actions/paymentJourney.actions";
import LimitTransactionModel from "./limitTransactionModal";
import Modal from "react-bootstrap/Modal";
import { isUserAuthenticated } from "../../../../../../_helpers";
import { dashboardActions } from "../../../../../../_actions";

interface Props {
  onSelect: any;
  amount: any;
  item: any;
  balance: any;
  fees: any;
  button_dess: any;
  onSelect_modefe: any;
  chackBalance: any;
  ChackBalancefun: any;
  MobileMoney: any;
  listBank: any;
  setGetFee: any;
  ChackLimet: any;
  invoicerecord: any;
}

export const Payoptions = (props: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [datadisplay, setDatadisplay] = useState("");
  const [selectMethodType, setSelectMethodType] = useState("");
  const [LoaderPage, setLoaderPage] = useState(false);
  const [startAPI, setStartAPI] = useState(false);

  const [balance, setBalance] = useState<any>([]);
  const [balanceRender, setBalanceRender] = useState(false);
  const [loderinapi, setLoderapi] = React.useState(false);
  const [loader, setLoader] = useState(true);
  const data = useSelector((state) => selectAllData(state));
  const [limitModal, setLimitModal] = useState(false);
  const [paymentName, setPaymentName] = useState("");
  const [newBalanceCheck, setNewBalanceCheck] = useState(false);

  console.log("selectMethodType ==>", selectMethodType);

  console.log("new BalanceCheck  ==>", newBalanceCheck);

  const balancelistData = useSelector((state) =>
    selectAllPayablesListData(state)
  );
  const [paymentacodiyan, setPaymentacodiyan] = useState<boolean>(false);
  const onSelect = (el: string) => {
    props.onSelect(el);
  };
  const onSelect_modefe = (el: string) => {
    props.onSelect_modefe(el);
  };

  useEffect(() => {
    const popup: any = searchParams.get("currency");
    setDatadisplay(popup);
  }, [searchParams]);

  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  const [item, setItem] = useState<any>();
  const [itemRender, setItemRender] = useState(true);
  const [errorShow, setErrorShow] = useState(false);
  const [mPesahighShow, setMPesahighShow] = useState(false);
  const [pesalinkhighShow, setPesalinkhighShow] = useState(false);
  const [data_ahahhahhajaja, setData_sjshhsgsjs] = useState(false);
  const [selectMethod, setSelectMethod] = useState("");
  const [selectType, setSelectType] = useState("");
  const [chackBalance, setChackBalance] = useState(true);
  const [countryList, setCountrtList] = useState<any>();
  const [startgetapi, setStartgetapi] = useState(true);
  const [RenderData, setRenderData] = useState(true);
  const [render, setRender] = useState(true);
  const [LimetChack, setLimetChack] = useState(false);
  const [LimetMethoad, setLimetMethoad] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [BankMothed, setBankMothed] = useState<any>([]);
  const [Balanceloader, setBalanceloader] = React.useState(false)

  const dataList = useSelector((state) => selectPaymentJourneyData(state));
  const dispatch = useDispatch();

  let navigate = useNavigate();

  console.log(props?.MobileMoney?.methods, props?.listBank, selectMethod, BankMothed, "propsprops123");

  // get dashboard balance
  useEffect(() => {
    if (!!props?.listBank) {
      var ArrayData: any = [];
      for (
        let index = 0;
        index < props?.listBank?.methods?.pay_out?.length;
        index++
      ) {
        const element = props?.listBank?.methods?.pay_out[index];
        var object = {
          key: element.key,
          logo: element?.logo,
          correspondent: element?.correspondent,
          title: element.title,
          id: element.id,
        };
        ArrayData.push(object);
      }

      setBankMothed(ArrayData);
    }
  }, [props?.listBank]);
  useEffect(() => {
    if (isUserAuthenticated() && render) {
      setRender(false);
      dispatch(
        dashboardActions.getBalance(
          "/wallet/get-balance",
          setBalanceRender, setBalanceloader
        ) as any
      );
      //   setTimeout(()=>{
      //     setBalanceRender(true);
      //   },4000)
      console.log(item?.amount, item, "item?.amount123");
    }
  }, [render, isUserAuthenticated]);
  useEffect(() => {
    if (balanceRender && data?.GET_DASHBOARD_BALANCE_SUCCESS !== undefined) {
      setBalanceRender(false);
      setBalance(data.GET_DASHBOARD_BALANCE_SUCCESS);
      setLoderapi(false);
      setLoader(false);
      console.log(data, "data.GET_DASHBOARD_BALANCE_SUCCESS");
    }
  }, [data, balanceRender]);
  useEffect(() => {
    if (RenderData) {
      setRenderData(false);
      dispatch(paymentJourneyActions.getCountriesBalances() as any);
    }
  }, [RenderData]);
  useEffect(() => {
    if (
      startgetapi &&
      dataList?.GETCOUNTRIES_BALANCES_SUCCESS !== undefined &&
      !!datadisplay
    ) {
      const listData = dataList?.GETCOUNTRIES_BALANCES_SUCCESS.find(
        (ele: any) => ele.currency === datadisplay
      );
      setCountrtList(listData);
      setLoader(false);
      setStartgetapi(false);
    }
  }, [startgetapi, dataList]);
  console.log("balance shows  ===>", balance);

  console.log("dataList shows  ===>", dataList);
  console.log("chackBalance =>", chackBalance);

  useEffect(() => {
    if (!!props.balance && props.fees && props.item && props.item.amount) {
      if (
        props.balance[0]?.available_balance / 100 <=
        props.fees["pesalink"] + props.item.amount
      ) {
        setErrorShow(true);
      } else {
        setErrorShow(false);
      }
      if (1000000 <= props.item.amount) {
        setPesalinkhighShow(true);
      } else {
        setPesalinkhighShow(false);
      }
      if (250000 < props.item.amount) {
        setMPesahighShow(true);
      } else {
        setMPesahighShow(false);
      }
    }
  }, [props]);
  useEffect(() => {
    if (
      itemRender &&
      balancelistData?.GETSELECTEDPAYABLES_SUCCESS !== undefined
    ) {
      setItemRender(false);
      setItem(balancelistData.GETSELECTEDPAYABLES_SUCCESS);
    }
  }, [balancelistData, itemRender]);
  console.log('xdfgxgvddgfg', LimetChack, dataList);

  useEffect(() => {
    console.log('xdfgxgvddgfg', LimetChack, dataList);

    if (LimetChack && dataList?.GETPAYOUT_PAYMENTLIITS_SUCCESS !== undefined) {
      console.log('xdfgxgvddgfg', LimetChack, dataList);

      if (dataList?.GETPAYOUT_PAYMENTLIITS_SUCCESS?.success == true) {
        console.log('xdfgxgvddgfg', LimetChack, dataList);

        setLimetChack(false);
        setLimetMethoad(true);
        if (dataList?.CHACKBALANCE_SUCCESS !== undefined) {
          if (dataList?.CHACKBALANCE_SUCCESS?.success == true) {
            setStartAPI(false);
            setLoaderPage(false);
            if (selectMethodType == "RTGS") {
              setSelectType("RTGS");
              onSelect("RTGS");
            } else if (selectMethodType == "EFT") {
              setSelectType("RTGS");
              onSelect("EFT");
            } else if (selectMethodType == "SWIFT") {
              setSelectType("SWIFT");
              onSelect("SWIFT");
            } else if (selectMethodType == "Bank") {
              // if (pesalinkhighShow == false) {
              onSelect("Bank");
              // }
            }
            props.setGetFee(dataList?.CHACKBALANCE_SUCCESS?.data)
            setChackBalance(true);
          } else {
            setErrorMessage(dataList?.GETPAYOUT_PAYMENTLIITS_SUCCESS.message);
            setChackBalance(false);
            setStartAPI(false);
            setLoaderPage(false);
          }
        }
      } else {
        setLoaderPage(false);
        setLimetChack(false);
        setLimetMethoad(false);
        setErrorMessage(dataList?.GETPAYOUT_PAYMENTLIITS_SUCCESS.message);
      }
    }
  }, [LimetChack, dataList?.GETPAYOUT_PAYMENTLIITS_SUCCESS]);
  // useEffect(() => {
  //   if (LimetChack && dataList?.GETPAYOUT_PAYMENTLIITS_SUCCESS !== undefined) {
  //     if (dataList?.GETPAYOUT_PAYMENTLIITS_SUCCESS?.success == true) {
  //       setLimetChack(false);
  //       setLoaderPage(false);
  //       setLimetMethoad(true);
  //     } else if (dataList?.GETPAYOUT_PAYMENTLIITS_SUCCESS?.success == false) {
  //       setLoaderPage(false);
  //       setLimetChack(false);
  //       setLimetMethoad(false);
  //       setErrorMessage(dataList?.GETPAYOUT_PAYMENTLIITS_SUCCESS.message);
  //       // toast.error(dataList?.GETPAYOUT_PAYMENTLIITS_SUCCESS.message, {
  //       //   duration: 8000,
  //       // });
  //     }
  //   }
  // }, [LimetChack, dataList?.GETVALIDATE_ACCOUNT_SUCCESS]);
  useEffect(() => {
    if (dataList?.CHACKBALANCE_SUCCESS?.success == true) {
      setNewBalanceCheck(true);
      setLoaderPage(false);
    } else {
      setNewBalanceCheck(false);
      setLoaderPage(false);
    }
  }, [dataList?.CHACKBALANCE_SUCCESS]);

  console.log("balance payoptions  ===>", props, props?.item?.amount >= 1000000 && datadisplay == "KES" ? !!props?.item?.invoice_record ? 'CLICK' : 'NO' : 'CLICK');

  return (
    <>
      {LoaderPage && (
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              display: "flex",
              position: "absolute",
              top: 150,
              bottom: 0,
              right: 0,
              left: 0,
              justifyContent: "center",
              alignItems: "center",
              zIndex: 10,
            }}
          >
            <CircularProgress color="success" />
          </Box>
        </ThemeProvider>
      )}
      {/* {!datadisplay ?
                <div className='m-pesa-container'>
                    <div className='m-pesa-container__heading'>
                        <h4>Please enter payment details</h4>
                    </div>
                    <div className='m-pesa-container__option-wrapper'>
                        {props.button_dess == true || datadisplay == '' ?
                            <div className='m-pesa-container__pay-option' onClick={() => {
                                if (mPesahighShow == false
                                ) {
                                    onSelect('MPesa')
                                }
                            }}>
                                <div className='option-container'>
                                    <MPessa />
                                    <span>M-Pesa</span>
                                </div>
                                <div className='fee-area'>
                                    <div className='arrow'>
                                        <RightArrow />
                                    </div>
                                </div>
                            </div> : <div id={data_ahahhahhajaja == true ? 'ajjahaggagga' : "ajjahaggagga_two"} className={data_ahahhahhajaja == true ? 'm-pesa-container__pay-option_two' : "m-pesa-container__pay-option"}
                                onClick={() => {
                                    onSelect_modefe('')
                                    setData_sjshhsgsjs(true)

                                }}
                            >
                                <div className='option-container'>
                                    <MPessa />
                                    <span>M-Pesa</span>
                                </div>
                                <div className='fee-area'>
                                    <div className='arrow'>
                                        <RightArrow />
                                    </div>
                                </div>
                            </div>}
                        {mPesahighShow == true ? (<div className='mpesa-container__errorMsg'>The amount exceeds the maximum limit of KES 250,000 for M-Pesa payments. Please enter a lower amount to proceed with M-Pesa or select a different payment method.</div>) : ""}
                        {(props.button_dess == true || datadisplay == '')
                            ?
                            <div style={{ position: 'relative' }} className='m-pesa-container__pay-option' onClick={() => {
                                if (pesalinkhighShow == false
                                ) {
                                    setSelectMethodType("Bank")
                                    props.ChackBalancefun('pesalink')
                                    setLoaderPage(true)
                                    setTimeout(() => {
                                        setStartAPI(true)
                                    }, 5000)
                                }
                            }}>
                                <div className='option-container'>
                                    <BankTransfer />
                                    <span>Bank Transfer  <span className='bottomtext'>via Pesalink</span></span>

                                </div>
                                <div className='fee-area'>
                                    <div className='arrow'>
                                        <RightArrow />
                                    </div>
                                </div>

                            </div> :
                            <div id={data_ahahhahhajaja == true ? 'ajjahaggagga' : "ajjahaggagga_two"} style={{ position: 'relative' }}
                                className={data_ahahhahhajaja == true ? 'm-pesa-container__pay-option_two' : "m-pesa-container__pay-option"}
                                onClick={() => {

                                    setData_sjshhsgsjs(true)
                                    onSelect_modefe('')

                                }}
                            >
                                <div className='option-container'>
                                    <BankTransfer />
                                    <span>Bank Transfer  <span className='bottomtext'>via Pesalink</span></span>

                                </div>
                                <div className='fee-area'>
                                    <div className='arrow'>
                                        <RightArrow />
                                    </div>
                                </div>

                            </div>
                        }
                       
                        {/* available balance  */}

      {/* <div className="available_balance">
                    {balance && 
                        <div>
                            <span className="balance_lable">Balance </span>
                            <span className="balance_amount">
                                {balance[0]?.available_balance 
                                    ? (
                                       balance[0].currency + " " + (balance[0].available_balance / 100).toLocaleString('en', {
                                            minimumFractionDigits: 2,
                                        })
                                    ) 
                                    : '0.00'
                                }
                            </span>
                        </div>
                    }
                </div>
                        {pesalinkhighShow == true ?
                            (<div className='mpesa-container__errorMsg'>
                                The amount exceeds the maximum limit of KES 999,999 for Pesalink payments. Please enter a lower amount to proceed with the Pesalink payment.</div>) : ""}
                    </div>
                </div>
                :  */}

      <div className="m-pesa-container">
        <div className='available_com'>
          {balance &&
            <div>
              <span className="balance_lable">Available Balance </span>
              <span className="balance_amount">
                {!datadisplay && balance[0]?.available_balance
                  ? balance[0]?.currency +
                  " " +
                  (balance[0]?.available_balance / 100).toLocaleString(
                    "en",
                    {
                      minimumFractionDigits: 2,
                    }
                  )
                  : !!datadisplay && !!countryList
                    ? countryList.currency +
                    " " +
                    countryList?.available_balance / 100
                    : "0.00"}
              </span>
            </div>
          }
        </div>
        <div className="calss_accordion_contenar">
          <ThemeProvider theme={theme}>
            <div
            aria-disabled={true}
              className="maian_adaat_listtts"
              onClick={() => {
                if (props.button_dess == true) {
                  if (paymentacodiyan == false) {
                    setPaymentacodiyan(true);
                  } else {
                    setPaymentacodiyan(false);
                  }

                }
              }}
            >
              <div className="avayar_text" >
                <Avatar className="Avatar_fast">3</Avatar>
                <p className="accordion_p_data_contean">Payment Method</p>
              </div>

              {paymentacodiyan == false ? (
                <ExpandMoreIcon style={{ color: "#1D1D1D" }} />
              ) : (
                <ExpandLessIcon style={{ color: "#1D1D1D" }} />
              )}
            </div>
            {paymentacodiyan == true ? (
              <div className="accordion_details_contean">
                <div className="mainana_cantena_daat_potrrr">
                  {props?.MobileMoney?.methods?.pay_out?.length > 0 ? (
                    <>
                      {props.button_dess == true || !datadisplay ? (
                        <Button
                          className={
                            data_ahahhahhajaja == true
                              ? "Minn_button_mpesa_datata"
                              : "calss_not_active_data"
                          }
                          onClick={() => {
                            if (mPesahighShow == false) {
                              onSelect("Mobile Money");
                            }
                          }}
                        >
                          <MobileIcon />{" "}
                          <p className="textTypo">Mobile Money</p>
                        </Button>
                      ) : (
                        <Button
                          id={
                            data_ahahhahhajaja == true
                              ? "ajjahaggagga"
                              : "ajjahaggagga_two"
                          }
                          className="calss_not_active_data"
                          onClick={() => {
                            onSelect_modefe("");
                            setData_sjshhsgsjs(true);
                          }}
                        >
                          <MobileIcon />
                          <p className="textTypo">Mobile Money</p>
                        </Button>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {/* {listMobileMoney} */}
                  {props?.listBank?.methods?.pay_out?.length > 0 ? (
                    <>
                      {props.button_dess == true || !datadisplay ? (
                        <Button
                          className={
                            data_ahahhahhajaja == true
                              ? "Minn_button_mpesa_datata"
                              : "calss_not_active_data"
                          }
                          onClick={() => {
                            setSelectMethod("Bank transfer");
                            setNewBalanceCheck(false);
                          }}
                        // disabled={props.chackBalance=="success"?false:true}
                        >
                          <VisaCardIcon />{" "}
                          <p className="textTypo">Bank transfer</p>
                        </Button>
                      ) : (
                        <Button
                          id={
                            data_ahahhahhajaja == true
                              ? "ajjahaggagga"
                              : "ajjahaggagga_two"
                          }
                          className="calss_not_active_data"
                          onClick={() => {
                            setData_sjshhsgsjs(true);
                            onSelect_modefe("");
                          }}
                        >
                          <VisaCardIcon />{" "}
                          <p className="textTypo">Bank Transfer</p>
                        </Button>
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
                {/* {props.chackBalance==false?<div className='mpesa-container__errorMsg'>Please top up your account to cover the payment.</div>:""} */}

                {pesalinkhighShow == true ? (
                  <div className="mpesa-container__errorMsg">
                    The amount exceeds the maximum limit of KES 999,999 for
                    Pesalink payments. Please enter a lower amount to proceed
                    with the Pesalink payment.
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {selectMethod == "Bank transfer" ? (
              <div
                style={{ display: "block" }}
                className="mainana_cantena_daat_potrrr"
              >
                {BankMothed?.map(
                  (data: any, index: number) => {
                    return (
                      <>
                        <Button
                          style={{ width: "100%", marginBottom: "10px" }}
                          className={
                            selectType == data?.key
                              ? "Minn_button_mpesa_datata"
                              : "calss_not_active_data"
                          }
                          onClick={() => {
                            setLimitModal(false);
                            if (data.title == "PESALINK") {
                              setSelectMethodType("Bank");
                            } else {
                              setSelectMethodType(data.title);
                            }
                            var body = {
                              currency: !!datadisplay ? datadisplay : "KES",
                              amount: props?.item?.amount,
                              paymentchannel: data.key,
                            };
                            props.ChackLimet(body);
                            props.ChackBalancefun(data?.key);
                            setNewBalanceCheck(false);
                            setLoaderPage(true);
                            setTimeout(() => {
                              setLimetChack(true)
                            }, 5000);
                          }}
                        >
                          {/* <div className="imgMetheds">
                            <img
                              src={data.logo}
                              width="100%"
                              height="100%"
                              alt=""
                            />
                          </div> */}
                          {data.title}
                          {/* <br />
                  <span className="description">Debits within 1 hour</span>
                  <p className="description_limit">
                    Upto 1M KES per transaction
                  </p> */}
                        </Button>

                        {!!ErrorMessage && LimetMethoad == false ? (
                          <>
                            {selectMethodType == data.title ? (
                              <div className="red invalid-feedback d-block">
                                {ErrorMessage}
                              </div>
                            ) : (
                              ""
                            )}
                          </>

                        ) : (
                          ""
                        )}
                      </>
                    );
                  }
                )}

              </div>
            ) : (
              ""
            )}

            {/* available balance  */}
            {/* 
            <div className="available_balance">
             
            </div> */}
            {dataList?.CHACKBALANCE_SUCCESS?.success == false ? <div className='mpesa-container__errorMsg'>
              Please top up your account to cover the payment.
            </div> : ""}
            {props?.item?.amount >= 1000000 && datadisplay == "KES" ? !props?.item?.invoice_record ?
              <div className="red invalid-feedback d-block">
                Please attach a supporting document for the transaction.
              </div> : ''
              : ''}
          </ThemeProvider>
        </div >
      </div >

      {limitModal && newBalanceCheck && (
        <>
          <Modal
            show={limitModal}
            onHide={() => {
              setLimitModal(false);
            }}
            backdrop="static"
            keyboard={false}
            aria-labelledby="ModalHeader"
            className="topup-modal"
            centered
            size="lg"
          >
            <LimitTransactionModel
              show={limitModal}
              paymentName={paymentName}
              setSelectMethodType={setSelectMethodType}
              setStartAPI={setStartAPI}
              setLoaderPage={setLoaderPage}
              setNewBalanceCheck={setNewBalanceCheck}
              setLimitModal={setLimitModal}
              setChackBalance={setChackBalance}
              onClose={() => {
                setLimitModal(false);
              }}
              onSave={(el: boolean) => {
                setLimitModal(false);
              }}
              onconfirm={(el: boolean) => {
                setLimitModal(false);
              }}
            />
          </Modal>
        </>
      )}
    </>
  );
};

const VisaCardIcon = () => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 0H2C0.89 0 0.00999999 0.89 0.00999999 2L0 14C0 15.11 0.89 16 2 16H18C19.11 16 20 15.11 20 14V2C20 0.89 19.11 0 18 0ZM18 14H2V8H18V14ZM18 4H2V2H18V4Z"
      fill="#025041"
    />
  </svg>
);
const MobileIcon = () => (
  <svg
    width="14"
    height="22"
    viewBox="0 0 14 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 19H9V18H5V19ZM2 22C1.45 22 0.979333 21.8043 0.588 21.413C0.196 21.021 0 20.55 0 20V2C0 1.45 0.196 0.979 0.588 0.587C0.979333 0.195667 1.45 0 2 0H12C12.55 0 13.021 0.195667 13.413 0.587C13.8043 0.979 14 1.45 14 2V20C14 20.55 13.8043 21.021 13.413 21.413C13.021 21.8043 12.55 22 12 22H2ZM2 17V20H12V17H2ZM2 15H12V5H2V15ZM2 3H12V2H2V3ZM2 17V20V17ZM2 3V2V3Z"
      fill="#025041"
    />
  </svg>
);
